'use client'
import { queryClient } from '@services/query'
import { QueryClientProvider } from '@tanstack/react-query'
import { FlagProvider } from '@unleash/nextjs/client'
import { ToastContainer, Bounce } from 'react-toastify'
import { MdCheck } from 'react-icons/md'
import { Remove } from 'react-huge-icons/bulk'

import 'react-toastify/dist/ReactToastify.css'
import { IconButton } from 'UI'
import cn from 'clsx'

const Providers: FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <FlagProvider config={{ disableRefresh: true }}>
        {children}
        <ToastContainer
          position='top-center'
          toastClassName='text-sm'
          closeOnClick
          pauseOnHover={false}
          icon={p => {
            return (
              <div
                className={cn('rounded-2xl min-h-12 min-w-12 grid place-items-center', {
                  'bg-error': p.type === 'error',
                  'bg-success': p.type === 'success'
                })}
              >
                {p.type === 'success' && <MdCheck size={20} className=' fill-white rounded-full bg-success-300 p-1' />}
                {p.type === 'error' && (
                  <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      opacity='0.4'
                      d='M9.96798 4.66592C10.8536 3.11136 13.1464 3.11136 14.032 4.66592L21.7041 18.1324C22.5649 19.6433 21.4445 21.5 19.6721 21.5H4.32789C2.55546 21.5 1.4351 19.6433 2.29587 18.1324L9.96798 4.66592Z'
                      fill='white'
                    />
                    <path
                      d='M13 17.5C13 18.0523 12.5523 18.5 12 18.5C11.4477 18.5 11 18.0523 11 17.5C11 16.9477 11.4477 16.5 12 16.5C12.5523 16.5 13 16.9477 13 17.5Z'
                      fill='white'
                    />
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M12 8.75C12.4142 8.75 12.75 9.08579 12.75 9.5V14.5C12.75 14.9142 12.4142 15.25 12 15.25C11.5858 15.25 11.25 14.9142 11.25 14.5V9.5C11.25 9.08579 11.5858 8.75 12 8.75Z'
                      fill='white'
                    />
                  </svg>
                )}
              </div>
            )
          }}
          closeButton={() => (
            <IconButton className='self-center'>
              <Remove fontSize={24} color='action' />
            </IconButton>
          )}
        />
      </FlagProvider>
    </QueryClientProvider>
  )
}

export default Providers
