'use client'
import Providers from './Providers'
import { Poppins } from 'next/font/google'
import 'styles/global.css'

export const Primary = Poppins({
  variable: '--font-primary',
  subsets: ['latin'],
  display: 'block',
  weight: ['300', '500', '600']
})

export default function RootLayout({
  children,
  modal
}: {
  children: React.ReactNode
  modal: JSX.Element
}) {
  return (
    <html lang='en' className='h-full'>
      {/*
        <head /> will contain the components returned by the nearest parent
        head.tsx. Find out more at https://beta.nextjs.org/docs/api-reference/file-conventions/head
      */}
      <head />
      <body className={`${Primary.variable} flex flex-col h-full font-primary font-medium text-base text-secondary`}>
        <Providers>
          {children}
          {modal}
        </Providers>
      </body>
    </html>
  )
}
